<div class="logo-grid" [@fadeInOnEnter]>
  <div class="logo">हरा</div>
  <div class="title">
    <span>hara.cafe</span>
  </div>
  <div class="subtitle">
    Hara Cafe is an app where people who prefer vegetarian lifestyle, cook delicious food and offer it to others
  </div>
  <div class="footer">To Campo Grande, MG, Brasil, 2023-24</div>
</div>
